function EvaluationGeneralData(){

    return (
        <div className={"break"}>
            <div className={"generalData"}>
                {/*test*/}

            </div>
        </div>
    );
}

export default EvaluationGeneralData;